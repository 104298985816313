import react from 'react';

const Service = () => {
    return(
        <>
            <h3>이용약관</h3>
            <div>본 서비스 이용약관은 귀하(이하 “귀하” 또는 “이용자”)가 마음센서(이하 "회사")가 제공하는 서비스인 “마음센서” 및 그 후속 버전의 서비스(이하 통칭하여 “본 서비스”)를 이용하는 데에 적용됩니다.</div>
            <div>본 서비스를 이용하기 전에 본 서비스 이용약관(이하 “본 약관”)을 주의 깊게 읽어주시기 바랍니다. 귀하가 본 서비스에 접근하여 이용하는 경우, 귀하는 본 약관 및 본 약관에 따라 변경된 약관의 모든 내용에 동의하는 것으로 간주합니다. 본 약관에 동의하지 않는 경우, 본 서비스의 이용을 중지하기 바랍니다.</div>
            <h4>제 1장. 총칙</h4>
            <h4>1. 목적</h4>
            <div>본 약관은 귀하가 회사가 제공하는 본 서비스를 이용하는 것과 관련하여 회사와 귀하의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.</div>
            <h4>2. 약관의 게시와 개정</h4>
            <div>회사는 관련 법령을 위배하지 않는 범위 내에서 필요하다고 판단할 경우 언제라도 본 약관을 변경할 수 있습니다. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 개정약관의 적용일자 전일까지 홈페이지 내 게시 링크를 통해 공지합니다. 귀하는 이에 동의하지 않는 경우 언제라도 본 서비스를 해지할 수 있으며, 만약 귀하가 개정약관의 적용일 이후에도 본 서비스 또는 서비스 앱을 계속 이용하는 경우 개정약관에 동의를 한 것으로 간주합니다.</div>
            <h4>3. 약관 외 준칙</h4>
            <div>본 약관에 규정되지 않은 사항에 대해서는 관련 법령 또는 회사가 정한 개별 서비스의 이용약관, 운영정책 및 규칙 등의 규정에 따릅니다.</div>
            <h4>4. 통지 및 연락 방법</h4>
            <ol>
                <li>본 서비스와 관련해 회사가 이용자에게 연락할 때에는 회사가 운영하는 웹사이트 내의 적당한 장소에 게시하거나 이용자가 기재한 정보를 이용하거나 기타 회사가 적당하다고 합리적으로 판단하는 방법에 의합니다.</li>
                <li>이용자가 가입신청시 기재한 정보에 변동이 생긴 경우, 이를 수정하거나 회사에 통지하여야 하며, 수정하지 않거나 통지하지 않음으로 인한 불이익에 대하여 회사는 일체의 책임을 지지 않습니다.</li>
            </ol>
            <h4>제 2장. 본 서비스에 대한 소유권 및 관련 라이선스</h4>
            <h4>1. 본 서비스에 대한 권리 귀속</h4>
            <ol>
                <li>본 서비스, 서비스 웹사이트, 본 약관, 개인정보 처리방침, 사용자 가이드라인, 기타 관련 문서 및 정보(이하 통칭하여 "회사 자산"이라 합니다)에 대한 저작권 기타 지식재산권은 별도의 계약 또는 법률에서 정한 경우를 제외하고 회사에 귀속됩니다.</li>
                <li>회사 자산은 저작권법을 비롯한 지식재산권법 및 기타 관련 법률에 의거하여 보호됩니다. 회사 자산은 회사의 영업비밀 및 독점정보를 포함하고 있으며, 귀하는 이러한 영업비밀 및 독점정보의 기밀성을 침해하지 않고 존중하며 보호하기 위해 필요한 모든 조치를 취해야 합니다.</li>
                <li>회사 자산에 대한 업데이트, 수정, 개선 사항 등에 대한 권리는 모두 회사에 귀속되거나, 경우에 따라서는 회사에 대한 라이선스 제공자에게 귀속됩니다. 본 서비스 제공 및 서비스 앱에 대한 라이선스 부여는 귀하에게 본 약관에 명시되어 있는 범위 외의 암묵적인 라이선스, 권리 또는 이익을 부여하지 않습니다.</li>
            </ol>
            <h4>2. 귀하가 보유한 정보에 대한 라이선스 범위</h4>
            <div>귀하는 귀하가 본 서비스 이용을 위해 접속한 단말에 포함된 주소록, 연락처 목록 및 고유전화식별자(IMEI, 고유장치-ID 등)에 대하여 회사가 관련 법령 및 회사의 개인정보 처리방침에 따라 접근하여 해당 정보를 수집하고 사용할 수 있는 목적 범위 내에서 회사에게 무상으로 사용할 수 있도록 해야합니다.</div>
            <h4>3. 회사 자산 및 라이선스에 대한 제한</h4>
            <ol>
                <li>귀하는 회사 자산을 상업적 용도로 사용할 수 없습니다.</li>
                <li>귀하는 회사 자산을 토대로 그 어떠한 언어로도 복사, 복제, 수정, 개조, 번역, 파생물 등을 만들 수 없으며 이를 배포할 수 없습니다.</li>
                <li>귀하는 회사 자산에 대한 라이선스, 서브 라이선스 기타 제3자에게 어떠한 권한이나 권리를 부여하는 행위, 리스, 임대, 대여, 양도, 판매, 배포, 수출 등의 행위를 할 수 없습니다.</li>
                <li>귀하는 회사 자산, 그리고 특히 본 서비스의 소스코드, 아이디어, 사용자 인터페이스, 알고리즘 등을 역설계, 디컴파일, 디스어셈블, 리버스 엔지니어링, 재구성, 식별, 확인 또는 발견하는 등의 행위를 할 수 없습니다.</li>
                <li>귀하는 회사 자산에 포함되어 있거나 이와 관련한 저작권, 지식재산권, 소유권 등 권리에 관한 고지를 제거 또는 변경하거나 모호하게 하는 행위를 할 수 없습니다.</li>
                <li>귀하는 회사 자산에 대한 저작권 또는 기타 권리를 보호하기 위하여 적용된 수단을 파괴, 무력화 또는 우회할 수 없습니다.</li>
            </ol>
            <h4>제 3장. 서비스 이용계약의 체결</h4>
            <h4>1. 이용계약의 체결</h4>
            <ol>
                <li>이용계약은 귀하가 본 서비스 이용 전 회사가 정한 절차에 따라 서비스 회원가입을 신청하고 회사가 이를 수락함으로써 체결됩니다.</li>
                <li>귀하는 가입신청 시 진실한 정보를 기재하여야 하며, 허위정보를 기재함으로 인한 불이익 및 법적 책임은 귀하에게 있습니다.</li>
                <li>회사는 제1항에 따른 귀하의 회원가입 신청을 지체없이 승낙하되, 다음 각 호의 경우 귀하의 회원가입을 수락하지 않을 수 있습니다. 회원가입 수락 이후 다음 각 호의 사유가 판명된 경우에는 회사는 즉시 귀하의 서비스 계정을 정지시키거나 귀하의 콘텐츠 및 개인정보를 삭제하는 등의 적절한 제한을 할 수 있습니다. 다만 이용자가 해당 사유가 존재하지 않음을 소명하는 경우에는 그러하지 않습니다. 회사는 법률적으로 통지가 허용되지 않는 경우(예를 들어, 통지하는 행위가 법령 또는 규제당국의 명령에 위배되거나 규제당국의 조사를 방해하는 결과를 초래하는 경우) 또는 통지로 인하여 이용자, 제3자, 회사에 손해가 발생할 수 있다고 합리적으로 판단되는 경우(예를 들어, 본 서비스의 보안을 해하는 경우)를 제외하고 귀하에게 이용제한 사실 및 그 제한사유를 지체없이 통지할 것입니다.</li>
                <ol>
                    <li>귀하가 허위의 명의 또는 타인의 명의를 이용하여 가입한 경우</li>
                    <li>귀하가 허위의 정보를 기재하거나 회사가 요구하는 내용을 기재하지 않은 경우</li>
                    <li>기타 관련 법령에 위반되거나 회사가 정한 기준에 반하는 경우</li>
                    <li>기타 회원으로 등록하는 것이 기술상 현저히 지장이 있다고 판단되는 경우</li>
                    <li>귀하가 본 약관 제7장 제2절에 의하여 회원등록이 말소된 적이 있는 경우(단 말소 후 3년이 경과한 자로서 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함)</li>
                </ol>
            </ol>
            <h4>2. 이용자격</h4>
            <ol>
                <li>본 서비스는 이러한 유형의 서비스를 허용하지 않는 지역에서는 이용할 수 없습니다.</li>
                <li>귀하는 본 약관의 내용을 모두 확인하였고, 본 약관을 준수할 능력이 있음을 확인합니다. 귀하가 본 약관을 이해하고 준수할 능력이 있다고 인정되는 경우에만 본 서비스를 이용할 수 있습니다.</li>
            </ol>
            <h4>제 4장. 계약당사자의 권리 및 의무</h4>
            <div>귀하는 본 서비스 및 회사 자산과 관련하여 아래 예시한 행위들을 포함한 어떠한 불법적인 행위도 하여서는 아니 되며, 이러한 행위를 하거나 시도하는 경우 귀하는 관련 법률에 따라 민∙형사상의 책임을 지게 될 수 있습니다.</div>
            <ol>
                <li>귀하는 불법적인 목적 또는 본 약관에서 명시적으로 허용하지 않은 그 어떠한 목적으로도 회사 자산을 사용할 수 없습니다.</li>
                <li>귀하는 회사 자산, 특히 본 서비스를 손상시키거나 사용이 불가능한 상태로 만들거나 또는 과부하가 걸리도록 하는 등의 방법으로 본 서비스에 장애를 발생시키는 행위를 해서는 안 됩니다.</li>
                <li>귀하는 회사 자산을 이용하여 worm, 바이러스 또는 파괴적인 코드 등을 전송하는 행위를 해서는 안 됩니다.</li>
                <li>귀하는 본 서비스를 이용함에 있어 다음 각 호의 행위를 포함하여 본 서비스의 제공목적 등에 비추어 회사가 부적절하다고 합리적으로 판단하는 다음 각 호에 준하는 행위를 하여서는 안 됩니다.</li>
                <ol type='a'>
                    <li>법령, 법원의 판결, 결정명령 또는 법령상 구속력을 가지는 행정조치에 위반되는 행위</li>
                    <li>공공질서 또는 미풍양속을 저해하거나 저해할 우려가 있는 행위</li>
                    <li>회사 또는 제3자의 저작권, 상표권, 특허권, 디자인권 등의 지식재산권, 명예권, 프라이버시권, 기타 법령상 또는 계약상의 권리를 침해하는 행위</li>
                    <li>과도하게 폭력적인 표현, 노골적인 성적 표현, 인종, 국적, 신조, 성별, 사회적 신분 등에 관하여 차별하는 표현, 자살, 자해, 약물 남용을 유인 또는 조장하는 표현, 기타 반사회적인 내용을 포함하고 타인에게 불쾌감을 주는 표현 등을 전송하거나 게시하는 행위</li>
                    <li>회사나 제3자를 사칭하는 행위 또는 의도적으로 허위 정보를 유포하는 행위</li>
                    <li>동일하거나 유사한 사진, 음성, 메시지 등을 불특정 다수의 이용자에게 송신하는 행위, 기타 회사가 스팸으로 판단한 행위</li>
                    <li>영업, 선전, 광고, 권유, 기타 영리를 목적으로 하는 행위(회사가 인정한 것은 제외함), 성적 또는 음란한 행위, 성매매를 요구하거나 유인하는 행위, 다른 이용자를 비방하거나 명예훼손 또는 모욕하는 행위, 기타 본 서비스에서 정해진 이용 목적과 다른 목적으로 본 서비스를 이용하는 행위</li>
                    <li>테러, 폭력, 마약, 인신매매 등을 하는 범죄단체나 반사회적 세력에 대한 이익 제공 기타 이에 동조하거나 지원하는 행위</li>
                    <li>종교 활동에 동참하거나 종교 단체에 가입할 것을 권유하는 행위</li>
                    <li>본 서비스의 서버와 네트워크시스템에 지장을 주는 행위, 봇, 치팅 툴, 기타 기술적인 수단을 이용하여 본 서비스를 불법으로 조작하는 행위, 본 서비스의 장애를 의도적으로 이용하는 행위, 동일한 민원을 필요 이상으로 반복하는 등 회사에 대해 부당한 문의 또는 요구를 하는 행위, 기타 회사의 본 서비스 운영 또는 다른 이용자의 본 서비스 이용을 방해하거나 이에 지장을 주는 행위</li>
                    <li>본 서비스를 이용하는 과정에서 녹음, 녹화, 캡처, 촬영 등을 통해 데이터를 생성하거나, 위와 같은 방법으로 생성한 데이터를 제3자에게 복제 및 유출하는 행위 또는 제3자를 통해 복제 및 유통시키는 행위</li>
                    <li>스토킹(stalking) 등 다른 이용자나 서비스 대상 고객을 괴롭히는 행위</li>
                    <li>다른 이용자나 서비스 대상 고객에 대한 개인정보를 동의없이 수집, 저장, 공개하는 행위</li>
                    <li>위 각 호의 행위 혹은 그에 준하는 행위 중 어느 하나에 해당하는 행위를 방조 또는 조장하는 행위</li>
                </ol>
                <li>귀하는 회사 및/또는 회사 자산과 관련이 있음을 암시하려는 의도로 온라인에서 허위의 웹사이트를 생성하거나 꾸미는 행위를 하여서는 안 됩니다.</li>
                <li>귀하는 상업적인 목적으로 회사 자산을 이용하거나, 회사 및/또는 회사 자산과 관련이 있음을 암시하는 행위를 하여서는 안 됩니다.</li>
                <li>귀하는 본 서비스가 제공하는 인터페이스 이외의 다른 방법이나 경로를 통해 본 서비스를 이용하거나 본 서비스에 접근하는 행위를 하여서는 안 됩니다.</li>
                <li>귀하는 회사가 정한 방법 외의 방법으로 본 서비스의 이용권을 현금, 재물 기타 경제적 이익과 교환하는 행위를 하여서는 안 됩니다.</li>
                <li>귀하는 본 약관 또는 사용자 가이드라인 등을 위반하는 행위를 하여서는 안 됩니다.</li>
            </ol>
            <h4>2. 무단 이용 제어</h4>
            <div>회사는 본 서비스에 대한 허용되지 않거나 위법한 이용을 차단하거나 제어하기 위해 필요한 모든 수단을 행사할 수 있는 권한을 가지고 있습니다. 이러한 권한 행사의 일환으로 회사는 기술적인 조치, IP 맵핑, 이동통신사업자에 대한 요청 등을 실시할 수 있습니다.</div>
            <h4>3. 개인정보 보호</h4>
            <ol>
                <li>회사는 관련 법령이 정하는 바에 따라 귀하의 개인정보를 보호하기 위하여 노력합니다. 회사가 귀하의 개인정보를 처리함에 관한 정책은 개인정보처리방침에서 확인할 수 있습니다.</li>
                <li>회원가입과 관련한 정보의 보유기간은 회원 탈퇴시까지이며, 그 외 서비스 대상 고객 정보 등의 보유기간은 개인정보처리방침에서 정하는 바에 따릅니다.</li>
                <li>회사는 회원의 귀책사유에 기인하는 등 회사의 귀책사유 없이 발생한 회원의 정보 유출 등을 포함하되 이에 한정되지 않는 결과, 손실 또는 손해에 대하여 일체의 책임을 지지 않습니다.</li>
            </ol>
            <h4>4. 서비스 대상 고객 정보 보관 및 이용</h4>
            <ol>
                <li>회사는 귀하 혹은 귀하가 소속되어 있거나 운영 중인 상담기관의 위탁을 받아 귀하가 서비스 이용 과정에서 생성하거나 입력한 서비스 대상 고객의 정보 (서비스 대상 고객 ID, 이메일 , 검사결과 등)를 이용자의 본 서비스 내 열람 등의 보다 나은 서비스 제공을 위한 목적에 한하여 보관하며, 개인정보보호법 등 관련 법령을 준수합니다.</li>
                <li>회사는 서비스 대상 고객 정보를 관련 법령 및 본 약관이 정하는 목적으로만 이용하며 제3자에게 이를 누출하거나 목적 외로 이용하지 않습니다.</li>
                <li>회사가 서비스 대상 고객 정보를 보관하는 동안 서비스 대상 고객이 개인정보보호법 등 관련 법령에서 정한 절차에 따라 회사에 자신의 정보에 대한 제공 및 이동을 요청하거나 상담사가 관련 법령에서 정한 절차를 준수하여 서비스 대상 고객의 동의를 받는 경우 회사는 회사가 운영하는 별도의 서비스 및 기타 회원이 요청한 매체를 통하여 서비스 대상 고객의 정보를 제공 및 이동할 수 있습니다.</li>
                <li>이용자가 본 서비스 외 타사의 연계 기능 혹은 서비스 등을 사용하면서 서비스 대상 고객의 상담기록 등 타인의 개인정보를 포함한 데이터를 전송하는 경우 개인정보보호법 등 관계법령을 준수해야 하며, 이로 인해 발생되는 문제에 대한 책임은 전적으로 이용자에게 있습니다.</li>
                <li>회사의 서비스 대상 고객 정보 보관에 대한 기타 구체적인 사항에 대해서는 개인정보 처리방침에서 명시된 바를 따릅니다.</li>
                <li>이용자는 서비스 대상 고객에게 마음센서 서비스 이용의 목적 및 서비스 이용과 관련한 안내를 제공하고, 서비스 대상 고객의 개인정보 수집 동의 여부를 확인하며, 관련 법령에 따라 해당 개인정보를 안전하게 관리할 의무가 있습니다. 마음센서는 이용자의 의무 불이행으로 인해 발생한 개인정보 유출, 법적 분쟁, 또는 기타 문제에 대해 어떠한 책임도 지지 않습니다. 이와 관련하여 회사에 손해가 발생한 경우, 회사는 이용자에게 구상권을 행사할 수 있으며, 구상권 행사는 손해 발생 시점부터 효력을 발휘하고, 그 범위는 회사가 입은 실제 손해액 전액에 해당합니다. 구상권 행사는 법적 절차에 따라 진행됩니다.</li>
            </ol>
            <h4>5. 피드백</h4>
            <div>회사에 제출된 본 서비스와 관련된 귀하의 의견, 제안 또는 피드백(이하 “피드백”이라 합니다)은 회사의 자산이 됩니다. 회사는 피드백에 대한 모든 권한을 독점적으로 보유합니다. 회사는 귀하 또는 다른 사람에 대한 보상 없이 상업적 또는 기타 목적으로 피드백을 사용할 수 있으며, 어떠한 피드백도 기밀로 취급되지 않습니다. 귀하는 피드백을 제공하였음을 이유로 본 서비스(또는 그에 대한 변경, 수정 포함)에 대한 권리를 취득하지 않음에 동의합니다. 회사는 귀하가 제출한 모든 피드백에 대한 합법성, 신뢰성, 적절성, 독창성 및 저작권에 대한 책임을 부담하지 않습니다.</div>
            <h4>6. 양도</h4>
            <div>귀하는 본 약관상의 권리 및 의무를 양도할 수 없습니다. 회사는 관련 법률에서 정한 절차에 따라 본 약관상의 권리 및 의무, 그리고 회사 자산에 대한 권리 및 의무를 제3자에게 양도 또는 이전할 수 있으며, 귀하는 이에 동의하지 않는 경우 언제라도 본 서비스를 해지할 수 있습니다.</div>
            <h4>제 5장. 서비스의 이용</h4>
            <h4>제1절 서비스의 내용</h4>
            <h4>1. 총칙</h4>
            <ol>
                <li>회사는 이용자에게 본 서비스 일체를 제공합니다.</li>
                <li>본 서비스는 이용자 혹은 이용자가 소속되어 있거나 운영중인 상담기관의 담당자가 회원가입 및 로그인 이후, 서비스 대상 고객을 대상으로 주기적 결과 모니터링을 위한 검사 실시 및 결과 확인, 상담 예약 및 관리, 상담 기록 등의 기능 일체를 제공합니다.</li>
                <li>이용자는 자신의 책임 하에 본 서비스를 이용해야 하며, 본 서비스에서 이루어진 모든 행위 및 그 결과에 대해서 일체의 책임을 져야 합니다. 회사는 등록된 패스워드를 이용하여 이루어진 일체의 행위를 본인 당사자의 행위로 간주할 수 있습니다.</li>
                <li>이용자는 본 서비스 이용을 위해 패스워드를 등록할 경우, 이를 부정하게 이용당하지 않도록 본인 책임 하에 이를 엄격하게 관리해야 합니다. 이용자는 자신의 계정 및 비밀번호가 도용되었거나 유출된 것을 인지한 즉시 회사에 통지하고, 회사의 안내에 따라야 합니다. 회사는 이용자가 회사에 그 사실을 통지하지 않거나 통지한 경우라도 회사의 안내를 따르지 않아 발생한 불이익에 대해서 회사의 고의 또는 과실이 없는 한 어떠한 책임도 지지 않습니다.</li>
                <li>회사는 본 서비스의 성능이나 보안을 개선하고, 기능이나 구성을 변경하며, 법령을 준수하고, 본 서비스 시스템상의 불법적인 활동이나 시스템을 악용하는 행위를 방지하기 위해 필요하다고 판단할 경우 언제라도 본 서비스의 전부 또는 일부 내용을 변경할 수 있으며 또한 그 제공을 중지할 수 있습니다. 이 경우 이용자에게 불리한 영향을 미치는 중대한 변경이나 제공 중지에 대해서는 사전에 통지함을 원칙으로 합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우, 보안이나 본 서비스의 호환가능성을 개선하기 위한 조치가 필요한 경우, 악용 행위의 방지 또는 법적 요건의 준수를 위해 필요한 경우 등에는 예외적으로 사후에 통지할 수 있습니다. 변경 등의 사유 또는 내용을 구체적으로 공지하기 어려운 경우에는 그 이유를 밝힙니다.</li>
                <li>회사는 이용자들에게 최고의 서비스를 제공하기 위하여 최선의 노력을 다합니다. 다만, 장비의 유지, 보수를 위한 정기 또는 임시 점검 또는 다른 상당한 이유로 본 서비스의 제공이 일시 중단될 수 있으며, 그 경우 법적 요건의 준수 또는 서비스 보안 유지 등 긴급한 사정이 없는 한 사전에 본 서비스 제공화면에 공지하겠습니다.</li>
            </ol>
            <h4>2. 서비스의 내용</h4>
            <div>회사가 이용자에게 제공할 수 있는 서비스  내용은 다음과 같습니다.</div>
            <ul className='ul_dash'>
                <li>주기적 결과 모니터링(Routine Outcome Monitoring)과 피드백을 위한 검사 실시 및 결과 확인</li>
                <li>기관 내 내담자 및 상담자 관리, 상담 예약 및 관리, 상담기록 확인, 직원의 이용 권한 설정</li>
            </ul>
            <div>서비스 내용은 회사 사정에 따라 변경될 수 있습니다. 상세한 시행 내용은 본 약관에서 규정하고 있는 통지 방법에 따라 별도 게시한 바에 따릅니다.</div>
            <h4>제2절 서비스 구매</h4>
            <h4>1. 멤버십 서비스</h4>
            <div>회사는 귀하에게 본 서비스를 정기 유료 멤버십 서비스의 형태로 유상으로 제공할 수 있습니다(이하 “멤버십 서비스”). 일정한 기간 동안 무상으로 회원에게 서비스의 기능을 직접 체험해 볼 수 있도록 멤버십 서비스를 제공할 수 있습니다.</div>
            <ol>
                <li><b>멤버십 서비스의 무상 체험 기간</b></li>
                <ol>
                    <li>회사는 이용자의 본 서비스에 대한 이해도를 높이기 위하여 회원의 요청에 따라 일정한 기간 (회사의 정책에 따라 수시 변경 가능) 동안 무상으로 회원에게 서비스의 기능을 직접 체험해 볼 수 있는 무상 체험 기간을 제공할 수 있습니다.</li>
                    <li>이용자는 무상 체험 기간 동안 구독 서비스를 이용할 시, 회사의 지식재산권 및 영업비밀에 대한 침해가 발생하지 않도록 각별한 주의를 기울여야 합니다. 명확히 하면, 이용자가 서비스의 화면의 전부 또는 일부를 회사의 승낙없이 무단으로 캡처 또는 녹화, 프린트하여 타인에게 제공하는 행위, 권한이 없거나 회사의 승인을 받지 않은 제3자로 하여금 서비스를 이용하게 하는 행위 등은 회사의 지식재산권 및 영업비밀을침해하는 행위로 간주될 수 있습니다.</li>
                    <li>회사는 무료로 제공되는 체험 서비스의 변경 및 중단으로 발생하는 문제에 대해서 일체의 책임을 부담하지 않습니다.</li>
                    <li>회사는 무상 체험 기간이 종료함과 동시에 이용자에게 즉시 결제금액을 청구할 수 있으며, 이용자가 결제를 완료할 때까지 계속하여 결제를 시도하거나, 이용자에게 결제를 요청하는 이메일, 문자, 전화 등을 진행할 수 있습니다. 이용자의 서비스 이용을 위한 요금 결제가 이뤄지지 않을 경우, 회사는 회사의 판단으로 본 계약을 해지할 수 있습니다. 이용계약이 해지됨에 따라 이용자의 데이터는 삭제될 수 있고, 삭제된 데이터는 사후적으로 복구되지 못할 수 있습니다. 이에 대한 책임은 전적으로 이용자에게 있습니다.</li>
                </ol>
                <li><b>멤버십 서비스 구매</b></li>
                <ul className='ul_none'>
                    <li>귀하는 회사가 제공하는 멤버십 서비스의 유형 중 하나를 선택하여 결제한 후 구독 서비스를 이용할 수 있으며, 수시로 회사는 귀하가 본 서비스를 이용하는 동안 멤버십 서비스를 구매할 수 있도록 제안할 수 있습니다.</li>
                </ul>
                <li><b>멤버십 서비스의 갱신 및 이용 중단</b></li>
                <ol>
                    <li>귀하가 자동으로 연장되는 멤버십 서비스를 구매하는 경우, 귀하의 결제 방법으로 귀하가 취소할 때까지 계속하여 멤버십 서비스에 대해 요금이 청구될 것입니다. 귀하의 최초 멤버십 서비스 약정 기간이 지나면 갱신일 이전에 이를 해지하지 않는 한 구독 시 동의한 가격으로 멤버십 서비스가 무기한 자동 갱신됩니다. 귀하의 카드 결제 및 기타 결제 정보는 본 약관에 따라 자동 결제를 위해 저장되어 이후에도 사용됩니다.</li>
                    <li>갱신을 원하지 않는 경우 현재 이용 중인 멤버십 서비스 이용 기간이 끝나기 24시간 전에 이용 중단을 신청해야 합니다. 이미 진행된 결제에 관한 이의가 있다면 maumsensor@gmail.com 계정 또는 서비스 내 고객센터를 통해 문의해야 합니다. 또한, 귀하의 결제 관련 정보 및 결제 취소 방법을 안내할 수 있는 은행 또는 결제 서비스 제공업체에 연락하여 이의를 제기할 수 있습니다. 귀하는 언제든지 마음센서 설정 또는 관련된 제3자 계정으로 이동하여 자동 카드 결제에 관한 동의를 조건 없이 철회할 수 있습니다.</li>
                    <li>멤버십 서비스 이용 중단의 경우, 다음 정기 결제일부터 결제가 중단되며 중단 시점을 기준으로 변경 수수료 또는 위약금이 제6조 청약철회 및 이용계약 해지 내 명시된 조건에 따라 부과될 수 있습니다.</li>
                </ol>
                <li><b>이용 중인 멤버십 서비스 종류의 변경</b></li>
                <ol>
                    <li>멤버십 종류 (또는 플랜) 변경의 경우 멤버십의 변경은 즉시 적용되며, 멤버십 변경에 따른 변경 수수료, 중도변경 위약금 및 변경 멤버십 서비스의 비용이 회사의 서비스 가격 정책에 따라 부과되며 이용자가 등록한 결제정보로 자동 결제될 수 있습니다.</li>
                    <li>멤버십 결제주기 변경의 경우 멤버십의 변경에 따른 결제 금액은 즉시 또는 다음 결제 주기에 청구되며, 멤버십 변경에 따른 변경 수수료, 중도변경 위약금 및 변경 멤버십 서비스의 비용이 회사의 서비스 가격 정책에 따라 부과되며 이용자가 등록한 결제정보로 자동 결제될 수 있습니다.</li>
                    <li>멤버십 이용 인원 (또는 계정) 수 변경의 경우, 즉시 또는 다음 결제 주기에 청구되며, 멤버십 변경에 따른 변경 수수료, 중도변경 위약금 및 변경 멤버십 서비스의 비용이 회사의 서비스 가격 정책에 따라 부되며 이용자가 등록한 결제정보로 자동 결제될 수 있습니다.</li>
                    <li>멤버십 변경에 따라 이용자의 데이터는 멤버십 제공 기능의 차이에 따라 부분적으로 삭제될 수 있고, 삭제된 데이터는 사후적으로 복구되지 못할 수 있습니다. 이에 대한 책임은 전적으로 멤버십 변경을 진행한 이용자에게 있습니다.</li>
                </ol>
                <li><b>멤버십 서비스 구성 내용의 변경</b></li>
                <ol>
                    <li>회사는 합당한 사전공지를 통해 멤버십 서비스를 중단하거나 멤버십 서비스의 내용을 변경할 수 있습니다. 또한, 멤버십 서비스의 불리한 변경에 관하여는 적어도 변경된 멤버십 서비스의 내용 통지 30일 이후에 그 효력이 발생합니다. 다만, 회사가 사전에 공지할 수 없는 버그 발생, 기기 결함, 보안 문제 등 부득이한 사정이 있는 경우에는 예외적으로 통지 없이 이러한 변경이 이루어질 수 있습니다.</li>
                    <li>귀하가 변경된 멤버십 서비스를 계속 사용하는 경우 변경된 서비스 이용 조건에 동의한 것으로 간주합니다.</li>
                </ol>
            </ol>
            <h4>2. 요금 지불</h4>
            <ol>
                <li>귀하는 멤버십 서비스를 구매하는 경우 회사 및 귀하가 이용하는 세금 및 수수료를 포함한 모든 요금을 지불할 것에 동의하며, 회사 또는 제3자(해당시)가 이용자에게 요금을 청구하도록 권한을 부여합니다.</li>
                <li>회사는 세금이 포함되지 않은 금액을 요금으로 게시할 수 있으며, 일부 결제 수단의 경우 발급자가 귀하에게 해외 거래 수수료나 기타 결제 수단 처리 관련 수수료 등의 수수료를 청구할 수 있습니다. 현지 세금은 사용된 결제 수단에 따라 달라질 수 있습니다. 자세한 사항은 결제 수단 서비스 제공업체를 통해 확인하시기 바랍니다.</li>
                <li>회사는 이용자의 단말의 설정을 변경할 수 없습니다. 이용자가 스스로의 판단으로 단말에서 인증절차가 생략된 간편결제 기능이 가능하도록 설정하여 상품을 구매하는 경우 이에 대해 회사는 관여할 수 없으며 일체의 책임을 부담하지 않습니다.</li>
                <li>유효기간 만료, 잔고 부족 등 사유로 결제가 정상적으로 처리되지 않았는데도 귀하가 계정을 해지하지 않는 경우, 유효한 결제 수단에 청구가 완료될 때까지 귀하의 서비스 이용이 제한될 수 있습니다.</li>
                <li>신용카드 만료, 예치금 부족 또는 기타 사유로 인하여 이용요금 결제가 성공적으로 진행되지 않은 경우, 이용자는 회사에 대하여 납부되지 않은 금액에 대한 책임을 지며, 회사는 이에 대하여 이용자에게 직접 미납금액을 청구하거나, 이용자에게 결제정보 정정을 요청하여 결제가 이루어질 때까지 계속하여 결제를 시도하거나, 회사의 판단으로 본 계약을 해지할 수 있습니다. 이용계약이 해지됨에 따라 이용자의 데이터는 삭제될 수 있고, 삭제된 데이터는 사후적으로 복구되지 못할 수 있습니다. 이에 대한 책임은 전적으로 이용자에게 있습니다.</li>
            </ol>
            <h4>3. 결제승인 취소 등</h4>
            <div>회사는 이용자가 부정, 위법한 목적, 약관 기타 서비스 취지에 반하는 방법으로 멤버십 서비스를 구매, 이용한 경우에는 결제 승인을 취소하거나 그 환전, 환불 등을 거절, 정지할 수 있습니다.</div>
            <h4>4. 제3자 결제에 대한 지불 책임</h4>
            <ol>
                <li>회사의 고의 또는 중과실로 인하여 귀하의 개인정보가 유출됨으로써 유료 멤버십 서비스 등이 구매되는 경우가 아닌 한, 귀하는 귀하의 계정과 관련하여 발생하는 모든 청구에 대한 책임을 부담합니다.</li>
                <li>귀하의 지인, 자녀, 가족이 귀하의 단말을 이용하여 유료 멤버십 서비스 등을 구매하지 않도록 주의를 기울이시기 바랍니다. 귀하의 지인, 자녀, 가족이 귀하의 동의 없이 또는 귀하의 뜻에 반하여 유료 멤버십 서비스 등을 구매하는 경우에도 관련 법률이 달리 규정하고 있는 경우를 제외하고 귀하는 요금 지불 책임을 지며, 그에 대하여 회사는 어떠한 책임도 부담하지 않습니다.</li>
            </ol>
            <h4>5. 청약철회 및 이용계약 해지</h4>
            <ol>
                <li>결제취소 : 구독 서비스를 구매한 이용자는 해당 서비스를 전혀 사용하지 아니하였을 경우에 한하여 결제일로부터 7일 이내에 별도의 수수료 없이 결제 취소(청약철회)를 할 수 있습니다. 단, 다음과 같은 경우에는 결제 취소(청약철회)가 제한됩니다.</li>
                <ol>
                    <li>구독 서비스 갱신 후, 귀하의 계정 로그인 등 서비스 이용이 1회 이상 진행된 경우</li>
                    <li>「문화산업진흥 기본법」 제2조제5호의 디지털콘텐츠의 제공이 일부라도 개시된 경우</li>
                    <li>재화의 내용을 확인하기 위하여 포장 등을 훼손한 경우를 제외하고, 귀하에게 책임이 있는 사유로 재화등이 멸실되거나 훼손된 경우</li>
                    <li>귀하의 사용 또는 일부 소비로 재화 등의 가치가 감소한 경우</li>
                    <li>시간이 지나 다시 판매하기 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우</li>
                    <li>복제가 가능한 재화 등의 포장을 훼손한 경우</li>
                    <li>귀하의 주문에 따라 개별적으로 생산되는 경우 등 결제취소(청약철회)를 인정하면 회사에게 회복할 수 없는 중대한 피해가 예상되는 경우로서 사전에 해당 거래에 대하여 별도로 그 사실을 고지하고 이용자의 서면(전자문서를 포함한다)에 의한 동의를 받은 경우</li>
                </ol>
                <div>예외적으로 구독 서비스의 내용이 표시ㆍ광고의 내용과 다르거나 계약내용과 다르게 이행된 경우에는 구매 후 3개월 이내, 구매 후 그 사실을 안 날 또는 알 수 있었던 날로부터 30일 이내에 결제취소(청약철회)를 할 수 있습니다.</div>
                <div>중도해지에 따른 위약금 및 변경수수료 : 귀하가 결제일로부터 7일을 경과하여 서비스를 해지하거나, 서비스를 사용한 이후 서비스를 해지하는 경우, 서비스를 이용하지 않은 기간 및 이용하지 않은 상품에 대한 환불은 제공되지 않으며 해당 사용분(구독 서비스를 위한 경품, 추가 이용 기간, 할인 금액 등 포함)에 대한 비용이 환급 금액에서 공제될 수 있습니다. 또한, 결제 플랫폼 수수료, 해지 시점까지의 기간에 해당하는 비용 및 해지에 따른 위약금 및/또는 변경수수료 (항목별 세금 및 기타수수료 미포함 금액을 500원 단위로 절상하여 산정)가 발생할 수 있다는 점을 주지하시기 바랍니다.</div>
                <ul className='ul_none'>
                    <li>멤버십 서비스 중도해지 위약금 (또는 변경수수료): 월 환산 서비스 이용 기간 (예: 50일의 경우 50/30 = 1.67개월 → 2개월으로 환산) x 기 지급된 월 환산 정가 대비 할인금액</li>
                </ul>
                <li>이용자는 서비스 이용계약을 언제든지 해지할 수 있습니다. 회사는 이용자의 해지 요청이 있는 경우 지체없이 그에 따라 해지에 필요한 조치를 취할 것입니다.</li>
                <li>회사는 이용자가 본 약관상 의무를 위반하거나 1년 이상 서비스에 접속하지 않는 경우, 기타 서비스 제공을 거부할 만한 합리적인 사유가 존재하는 경우 사전 통지 후 서비스 이용계약을 해지할 수 있습니다.</li>
                <li>서비스 이용계약이 해지되는 경우, 회사는 관련 법령 및 개인정보처리방침 등에 따라 회사가 보유하여야 하는 정보를 제외한 이용자 관련 개인정보 및 데이터 등 일체의 정보를 삭제합니다. 그에 따라 삭제된 정보는 사후적으로 해지가 철회되더라도 복구되지 못할 수 있습니다.</li>
                <li>Apple IAP(In-App-Purchase)의 취소 및 환불의 경우 Apple 미디어 서비스 이용 약관의 정책을, Google Play IAP(In-App-Purchase)의 취소 및 환불의 경우 Google Payments 서비스 약관의 정책을 따릅니다.</li>
            </ol>
            <h4>제 6장. 서비스 이용과 관련된 제 3자와의 관계</h4>
            <h4>1. 통신망 및 단말 사용에 대한 권한</h4>
            <ol>
                <li>본 서비스 이용을 위해 이동통신사업자, 유무선인터넷사업자 등 제3자의 통신망을 이용해야 하는 경우, 귀하는 그러한 제3자로부터 통신망 사용에 대한 적법한 권한을 받아야 하며, 회사가 이에 대해 관여하거나 어떠한 의무를 부담하지는 않습니다.</li>
                <li>귀하는 본 서비스를 제공하는 웹사이트에 접근할 수 있는 단말에 대한 적법한 사용권한을 보유해야 합니다. 귀하가 그러한 단말을 판매 또는 양도하거나 단말에 대한 권리를 상실하게 될 예정이라면, 귀하는 미리 해당 단말로부터 서비스 웹, 앱이나 관련 개인정보를 삭제해야 하며, 이를 삭제하지 않아 발생하는 문제에 대해서는 귀하가 전적인 책임을 부담합니다.</li>
            </ol>
            <h4>2. 제3자 소프트웨어 사용</h4>
            <div>귀하가 본 서비스를 이용함에 있어 제3자의 소프트웨어 또는 기술을 사용하는 경우 해당 소프트웨어 또는 기술의 이용행위에 대해서도 본 약관의 적용을 받게 됩니다.</div>
            <h4>3. 서비스 이용 시 발생하는 SMS 비용 부담</h4>
            <div>귀하가 단말의 SMS 기능을 사용하여 다른 사람에게 본 서비스 관련 메시지를 전달하거나 귀하가 소통하고자 하는 사람들을 본 서비스에 초대하는 경우, 해당 SMS 기능 사용과 관련하여 회사 및 제3자(SMS 서비스 제공자)가 귀하에게 비용을 각각 별도로 부과할 수 있습니다.</div>
            <h4>제 7장. 서비스 해지 및 이용제한 등</h4>
            <h4>1. 서비스 탈퇴</h4>
            <div>귀하가 계정 삭제를 원할 경우 서비스 내 “개인정보 수정” 기능에서 “회원탈퇴”를 통해 가능합니다. 만약 귀하가 구독 서비스에 가입하셨던 경우에는 본 서비스 탈퇴 전에 반드시 구독 서비스를 해지하여야 합니다. 이를 이행하지 않아 발생하는 모든 책임은 귀하가 부담합니다.</div>
            <h4>2. 회사에 의한 서비스 제공 종료 및 회원 탈퇴, 자격 상실 등</h4>
            <ol>
                <li>회사는 다음 각 호 또는 그러한 상황이 발생할 개연성이 충분하다고 판단하는 경우 귀하에게 회원가입시 등록된 정보(전자우편, 휴대폰 SMS/메신저앱(카카오톡) 등)로 서비스 중단 통지 후, 본 서비스 이용을 즉시 제한하거나 귀하의 계정을 일시 또는 영구적으로 중단시킬 수 있습니다. 다만 이용자가 본 서비스 중단 통지일로부터 30일 내 각 호 사유가 존재하지 않음을 소명하는 경우에는 그러하지 않습니다. 회사는 이러한 서비스 제공 제한 및 종료와 관련하여 법률에 별도로 정한 경우 외에는 일체의 책임을 지지 않습니다.</li>
                <ol>
                    <li>본 약관 제2장의 범위를 벗어난 이용행위, 제3장 제1조 또는 제4장 제1조에 위배되는 행위를 하는 경우</li>
                    <li>불법복제, 사기, 명예훼손, 성폭력특별법 및 아동청소년보호법 위반 등의 위법한 행위를 하는 경우</li>
                    <li>계정 해킹, 명의도용, 신용카드 부정사용이나 기타 이에 준하는 구매나 지불과 관련한 부적절한 행위를 하거나 그에 관여하는 경우</li>
                    <li>제3자의 인격권, 지식재산권, 재산권 등 권리를 침해하는 경우</li>
                    <li>본 서비스 이용행위와 관련하여 수사입건된 경우</li>
                    <li>사회통념상 부도덕한 행위를 하는 경우에 해당한다고 합리적으로 판단하는 경우</li>
                </ol>
                <li>회사는 법률적으로 통지가 허용되지 않는 경우(예를 들어, 통지하는 행위가 법령 또는 규제당국의 명령에 위배되거나 규제당국의 조사를 방해하는 결과를 초래하는 경우) 또는 통지로 인하여 이용자, 제3자, 회사에 손해가 발생할 수 있다고 합리적으로 판단되는 경우(예를 들어, 본 서비스의 보안을 해하는 경우)를 제외하고 귀하에게 종료 등의 서비스 제공 제한 또는 종료사유를 지체없이 통지할 것입니다.</li>
                <li>회사는 이용자가 법령, 본 약관 또는 본 서비스 관련 회사의 별도 운영 정책에서 정하는 기간 동안 본 서비스에 로그인 또는 접속한 기록이 없는 경우, 이용자에 대한 사전 통지 후 이용자의 계정정보를 삭제할 수 있으며, 이로 인해 본 서비스 이용계약이 해지될 수도 있습니다. 다만, 이용자가 회원가입시 회사에 유효한 이메일 주소를 제공하지 않는 등 이용자에게 통지할 수 없는 경우에는 통지 없이 해당 계정을 삭제할 수 있습니다.</li>
                <li>회사에 의한 서비스 제공 종료 및 회원 탈퇴, 자격 상실의 경우, 기존에 지불된 이용요금은 어떠한 경우에도 결제 취소되지 않으며, 사안의 경중에 따라 추가적인 위약금이 부과되고 청구될 수 있습니다.</li>
            </ol>
            <h4>제 8장. 면책 및 보증 제한 등</h4>
            <h4>1. 면책</h4>
            <div>귀하가 i) 본 약관 또는 관련 법령을 위반하는 경우, ii) 제3자의 권리를 침해하는 경우, iii) 본 서비스 및 회사 자산을 정당하지 않은 목적으로 사용하는 경우, iv) 본 서비스 및 회사 자산을 이용하여 다른 이용자와 분쟁이 발생하는 경우, v) 타인의 개인정보를 유출하거나 무단으로 사용한 경우 또는 vi) 본 서비스 관련 구매에 대한 세금 또는 비용이 발생하는 경우, 이로 인해 제3자가 회사, 회사의 라이센서, 회사의 동업자, 회사의 관계회사, 이사, 임직원, 거래업체 등(이하 통칭하여 "회사 측"이라 합니다)에 대해 클레임 또는 소송을 제기하여 회사 측에 손해(변호사 비용을 포함합니다)가 발생한다면 그 손해에 대해서는 귀하가 회사 측을 면책해야 합니다. 이러한 경우 회사 측은 귀하의 비용으로 법률대리인 선정을 비롯하여 분쟁 대응을 주도할 권리를 행사할 수 있습니다. 귀하가 해당 제3자와 합의, 조정 등을 고려하는 경우 반드시 회사의 사전 동의를 받아야 하며, 회사의 동의 없이 이루어진 합의나 조정 등은 회사 측에 대하여 효력이 없습니다.</div>
            <h4>2. 보증 제한</h4>
            <ol>
                <li>본 서비스는 귀하에게 현존 상태 그대로 제공되며 본 서비스의 상태에 대해 회사가 보증하지 않습니다. 회사는 귀하에 대한 본 서비스 제공과 관련하여 귀하의 이익, 귀하가 고려하는 특정한 목적에의 적합성, 귀하의 만족도, 귀하가 기대하시는 서비스 품질, 귀하가 득하시는 정보의 정확성 등에 대한 보증을 하지 않습니다.</li>
                <li>귀하의 본 서비스 이용과 관련하여 발생할 수 있는 어떠한 문제에 대해서도 회사는 법률에서 달리 규정하고 있지 않는 한 책임지지 않습니다. 귀하는 서비스 웹과 앱을 귀하의 단말로부터 제거하고 본 서비스를 이용하지 않음으로써 그러한 문제를 해결할 수 있습니다.</li>
                <li>회사는 본 서비스 또는 서비스 웹과 앱에 사실상 또는 법률상의 하자(안전성, 신뢰성, 정확성, 완전성, 유효성, 특정 목적과의 부합 여부, 보안 등에 관련된 결함, 오류와 버그, 권리침해 등을 포함함)가 없음을 명시적으로도 묵시적으로도 보증하지 않습니다. 회사는 이용자와 서비스 대상 고객에 대해 이러한 하자를 제거하고 본 서비스를 제공할 의무를 부담하지 않습니다.</li>
                <li>회사는 귀하의 본 서비스 이용과 관련하여 콘텐츠의 송수신, 바이러스에 대한 안전성 등에 대한 보증을 하지 않습니다.</li>
            </ol>
            <h4>3. 책임의 제한</h4>
            <ol>
                <li>귀하의 본 서비스 이용과 관련하여, 회사는 회사의 고의 또는 중대한 과실로 발생한 직접적인 손해 외에는 귀하에게 발생한 손해에 대한 어떠한 책임도 지지 않습니다. 예를 들어, 귀하에게 발생한 간접적인 손해, 징벌적 제재로 인한 손해, 휴업손해, 일실수익 등에 대해서는 책임을 지지 않습니다. 이와 같은 내용은 회사가 그러한 손해 발생 가능성에 대해 미리 고지 받은 경우에도 동일하게 적용됩니다. 회사의 고의 또는 중과실로 인하여 귀하의 개인정보가 유출됨으로써 인하여 제3자가 이용자 명의로 유료 서비스를 구매하는 경우 등 이용자의 서비스 구입에 관하여 회사가 손해배상책임을 부담하는 경우, 그 손해배상의 범위는 이용자가 증빙자료의 제출 등을 통해 증명한 유료 서비스의 구입 금액을 상한으로 합니다.</li>
                <li>회사는 이용자가 본 서비스에 접속 또는 이용하는 과정에서 발생하는 개인적인 손해, 제3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써 발생하는 손해, 제3자가 회사의 서버에 대한 전송 또는 회사의 서버로부터의 전송을 방해함으로써 발생하는 손해, 제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해, 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 회사의 고의나 중과실 없이 다른 이용자 또는 제3자의 행위로 인하여 이용자에게 발생한 손해에 대해서 책임을 부담하지 않습니다.</li>
            </ol>
            <h4>4. 회사의 서비스 제공에 관한 전제사실</h4>
            <div>귀하는 회사가 본 약관 제8장 제2조(보증 제한) 및 같은 장 제3조(책임의 제한)에 따라 소프트웨어 및 서비스를 제공하고 그 가격을 설정한 사실을 인정하고 동의합니다.</div>
            <h4>5. 권리의 포기</h4>
            <div>회사가 본 약관의 권리 또는 조항을 행사하거나 집행하지 않는 것을 그러한 권리 또는 조항의 포기로 볼 수 없습니다. 본 약관에 따라 회사가 가지는 권리의 포기는 회사가 서면으로 서명한 경우에만 유효합니다.</div>
            <h4>제 9장. 약관의 해석, 준거법 및 분쟁해결</h4>
            <h4>1. 전체 계약</h4>
            <div>본 약관은 회사와 귀하 사이의 완전한 계약을 나타냅니다. 본 약관의 조항을 시행할 수 없는 경우, 그러한 조항은 집행 가능하게 하는 데 필요한 정도로만 수정되어야 하며 나머지 조항의 집행 가능성 또는 유효성에 영향을 미치지 않아야 하고, 그 조항들은 완전한 효력을 유지합니다.</div>
            <h4>2. 조항의 제목</h4>
            <div>본 약관 조항의 제목은 편의를 위해서만 제공되는 것으로, 본 약관의 일부를 구성하지 않으며 본 약관 조항의 내용을 제한하거나 본 약관 조항에 영향을 미치지 않습니다.</div>
            <h4>3. 보전처분 등</h4>
            <div>귀하는 본 약관에 따라 귀하가 부담하는 회사에 대한 의무가 대체할 수 없는 성격임을 인정합니다. 귀하의 의무위반으로 인하여 회사에 돌이킬 수 없는 손해가 발생할 수 있으며, 그 손해는 금전적 배상만으로는 보전되기 어려울 수 있으므로, 귀하가 의무를 위반할 것이 예상되는 경우에는 회사는 가처분 등의 보전처분을 신청할 수 있습니다.</div>
            <h4>4. 약관의 해석</h4>
            <div>본 약관은 한국어를 정본으로 합니다. 본 약관과 영문 버전 약관 사이에 차이가 있을 경우, 본 약관에 따른 해석이 우선합니다.</div>
            <h4>5. 준거법 및 관할</h4>
            <div>원칙적으로 본 약관의 해석은 대한민국 법률에 따르고 본 약관과 관련된 분쟁은 서울중앙지방법원을 제1심 전속관할로 하는데 동의합니다. 그러나 만일 귀하가 거주하는 국가의 법률이 약관 해석 및 관할에 관하여 달리 정하고 있는 경우, 해당 법률에 따릅니다. 추가 정보: 본 서비스에 대한 정보 또는 기술 지원을 받으려면 maumsensor@gmail.com 로 메일을 보내주시기 바랍니다.</div>
            <h4>제정일: 2024. 11. 13</h4>
        </>
    );
};

export default Service;