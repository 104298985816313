import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PDFView from 'components/TablePDFView';
import { ScheduleKindKo, ServiceKo, SessionStateKo, YyyyMmDd } from 'components/Function/ChangeText';

const TablePDFContainer = () => {
    const { search, start, end, counselorId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const [loading, setLoading] = useState(true);
    const [sumFee, setSumFee] = useState(0);
    window.onafterprint = function(){
        window.close();
    };
    const [schedules, setSchedules] = useState([]);
    const [user, setUser] = useState({});
    const keys = [
        { key: 'No', label: 'No.'},
        { key: 'CounselorName', label: '상담자'},
        { key: 'ClientName', label: '내담자'},
        { key: 'NickName', label: '내담자ID'},
        { key: 'GroupName', label: '그룹'},
        { key: 'StartDateValue', label: '상담일시'},
        { key: 'KindDetailValue', label: '상담 종류'},
        { key: 'ServiceValue', label: '상담 방식'},
        { key: 'StateValue', label: '방문 여부'},
        { key: 'FeeValue', label: '비용'}, 
        { key: 'IsFeeValue', label: '수납 여부'}
    ];

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/schedule/${cookies.LoginKey}`, config)
        .then(async(res) => {
            if(!res.data.ErrorMessage){
                await axios.get(`/api/manager/group/${cookies.LoginKey}`, config)
                .then((res2) => {
                    let loadSchedules = [...res.data.ScheduleDatas];
                    let loadUser = { ...res.data.UserData };
                    let loadGroups = [...res2.data.GroupDatas];
                    
                    loadSchedules = loadSchedules.filter((item) => (item.Kind.State === 1));
                    loadSchedules = loadSchedules.sort((a, b) => {
                        if(a.StartDate > b.StartDate) return 1;
                        if(a.StartDate < b.StartDate) return -1;
                        else return 0;
                    });
                    for(let i = 0; i < loadSchedules.length; i++){
                        loadSchedules[i].No = i+1;
                        for(let j = 0; j < loadGroups.length; j++){
                            if(loadGroups[j].Client_ids.indexOf(loadSchedules[i].Client_id._id) > -1){
                                loadSchedules[i].GroupName = loadGroups[j].Name;
                                break;
                            }
                        }
                        loadSchedules[i].CounselorName = loadSchedules[i].Counselor_id.Name;
                        loadSchedules[i].ClientName = loadSchedules[i].Client_id.Name_decrypt;
                        loadSchedules[i].NickName = loadSchedules[i].Client_id.NickName;
                        loadSchedules[i].StartDateValue = `${YyyyMmDd(loadSchedules[i].StartDate)} ${loadSchedules[i].StartTime}`;
                        loadSchedules[i].KindDetailValue = ScheduleKindKo(loadSchedules[i].Kind.Detail);
                        loadSchedules[i].ServiceValue = ServiceKo(loadSchedules[i].Service);
                        loadSchedules[i].StateValue = SessionStateKo(loadSchedules[i].State);
                        loadSchedules[i].IsFeeValue = loadSchedules[i].IsFee ? 'O' : 'X';
                        loadSchedules[i].FeeValue = loadSchedules[i].Fee ? loadSchedules[i].Fee : '';
                    }
                    const tumpFilter = filterDatas(loadSchedules);
                    let sum = 0;
                    for(let i = 0; i < tumpFilter.length; i++){
                        if(tumpFilter[i].IsFee){
                            sum += tumpFilter[i].Fee ? Number(tumpFilter[i].Fee) : 0;
                        }
                    }
                    setSumFee(sum);
                    setSchedules(tumpFilter);
                    setUser(loadUser);
                    setTimeout(() => {
                        setLoading(false);
                        setTimeout(() => {
                            window.print();
                        }, [200]);
                    }, [1800]);
                });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [setSchedules, enqueueSnackbar, cookies.LoginKey]);

    const filterDatas = (datas) => {
        let tumpData = [...datas];
        const searchInfo = search !== 'null' ? search : '';
        if(start !== 'null'){
            tumpData = tumpData.filter((a) => (new Date(a.StartDate) >= new Date(start)));
        }
        if(end !== 'null'){
            tumpData = tumpData.filter((a) => (new Date(a.StartDate) <= new Date(`${end} 23:59:59`)));
        }
        if(counselorId !== 'null'){
            tumpData = tumpData.filter((a) => (a.Counselor_id._id === counselorId));
        }
        if(tumpData){
            return tumpData.filter((item) => {
                let returnData = false;
                for(let i = 0; i < keys.length; i++){
                    if(item[`${keys[i].key}`]?.toString().indexOf(searchInfo) > -1){
                        returnData = true;
                        break;                    
                    }
                }
                return returnData;
            });
        }else{
            return [];
        }  
    };

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return(
        <PDFView 
            loading={loading}
            keys={keys}
            user={user}
            schedules={schedules}
            sumFee={sumFee}
        />
    );
};

export default TablePDFContainer;