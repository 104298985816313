import react from 'react';
import Privacy01 from 'images/privacy01.png'
import Privacy02 from 'images/privacy02.png'
import Privacy03 from 'images/privacy03.png'
import Privacy04 from 'images/privacy04.png'

const Privacy = () => {
    return(
        <>
            <h3>개인정보처리방침</h3>
            <div>마음센서 (이하 “<b>회사</b>”라 함)는 회원(이하 “<b>이용자</b>”라 함)의 개인정보를 처리함에 있어 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 (이하 “정보통신망법”)과 「개인정보 보호법」(이하 “개인정보법”) 등 관련 법령 및 규정, 가이드라인 등을 준수하고 있습니다.</div>
            <div>회사는 개인정보법 제30조에 따라 회사가 제공하는 마음센서 서비스 및 그 후속 서비스(이하 총칭하여 “본서비스”)와 관련하여 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립, 공개합니다.</div>
            <table className='table_image'>
                <tr>
                    <td>
                        <div><img src={Privacy01} alt='일반 개인정보 수집 이미지' /></div>
                    </td>
                    <td>
                        <div><img src={Privacy02} alt='개인정보 처리목적 이미지' /></div>
                    </td>
                    <td>
                        <div><img src={Privacy03} alt='개인정보 보유 기간 이미지' /></div>
                    </td>
                    <td>
                        <div><img src={Privacy04} alt='고충처리부서 이미지' /></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>일반 <b>개인정보 수집</b></div>
                        <div><small>(이름, 이메일주소, 연락처)</small></div>
                    </td>
                    <td>
                        <div>개인정보 <b>처리목적</b></div>
                        <div><small>(회원가입 및 회원관리, 서비스 제공)</small></div>
                    </td>
                    <td>
                        <div>개인정보의 <b>보유 기간</b></div>
                        <div><small>(개인정보는 탈퇴시 파기, 일부는 관련 법령에 따름)</small></div>
                    </td>
                    <td>
                        <div><b>고충처리부서</b></div>
                        <div><small>(책임자: CEO)<br/>maumsensor@gmail.com</small></div>
                    </td>
                </tr>
            </table>
            <div>※ 자세한 내용은 아래의 개인정보처리방침을 확인하시기 바랍니다.</div>
            <h4>1. 개인정보의 처리 목적</h4>
            <div>회사는 다음 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음 목적 외 용도로는 이용되지 않으며, 이용 목적이 변경되면 개인정보법 제15조 제2항 및 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</div>
            <table className='table_text'>
                <tr>
                    <td>구분</td>
                    <td>처리 목적</td>
                </tr>
                <tr>
                    <td>회원 가입 및 관리</td>
                    <td>본인 확인, 이용자 식별, 가입의사 확인, 부정이용 및 비인가 사용 방지, 사기 또는 법령 및 이용약관을 위반하는 이용자에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 기타 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재</td>
                </tr>
                <tr>
                    <td>서비스 제공</td>
                    <td>이용자의 상담기록의 저장 및 저장된 상담정보의 검색, 이용자의 효과적인 상담 진행 및 상담 관리, 서비스 사용에 관련된 결제 처리, 서비스 사용과 관련된 모든 문의사항, 불만처리, 공지사항 전달, 서비스 이용에 대한 통계 및 이를 활용한 서비스 환경 구축 및 서비스 개선,  증거기반치료와 관련한 과학적 연구를 통해 서비스 개선 및 개발</td>
                </tr>
                <tr>
                    <td>마케팅 및 광고</td>
                    <td>이벤트 참여, 서비스 홍보 및 판매 권유</td>
                </tr>
            </table>
            <div>회사는 개인정보법에 따라 당초 수집 목적과 합리적으로 관련된 범위에서 다음의 사항을 고려하여 정보주체의 동의 없이 개인정보를 이용·제공할 수 있습니다.</div>
            <ul>
                <li>당초 수집 목적과 합리적으로 관련성이 있는지 여부</li>
                <li>개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의 추가적인 이용 또는 제공에 대한 예측가능성이 있는지 여부</li>
                <li>정보주체의 이익을 부당하게 침해하는지 여부</li>
                <li>가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부</li>
            </ul>
            <h4>2. 개인정보의 수집항목 및 수집방법</h4>
            <h4>가. 개인정보 수집 항목</h4>
            <div>회사는 회원 가입시 또는 서비스 이용 과정에서 아래와 같은 서비스 제공을 위해 필요한 최소한의 개인정보를 수집하고 있습니다. </div>
            <h4>&lt;정보주체의 동의를 받지 않고 처리하는 개인정보 항목&gt;</h4>
            <div>회사는 다음의 개인정보 항목을 개인정보 보호법 제15조 제1항 제4호(‘계약 이행’)에 따라 정보주체의 동의없이 처리하고 있습니다.</div>
            <h4>1) 이용자의 회원 가입 및 관리를 위해 필요한 정보</h4>
            <div>이름, 이메일 주소, 연락처</div>
            <h4>2) 이용자의 서비스 사용을 위해 필요한 정보</h4>
            <div>서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.</div>
            <div>이용자가 작성한 단문 메시지, 이용자가 작성한 글 및 상담 기록, 로그데이터, 쿠키, 접속 정보, IP 주소, 단말 성능, 대역폭, 서비스 이용 통계, 네트워크 유형, 통신사 정보, 단말기 정보(단말기 유형, 모델명, 단말기 아이디, OS) 오류 발생시 오류 발생 일시 및 오류 발생 당시 사용 중인 기능 및 브라우저의 상태, 광고 식별자, 이용자와 다른 이용자 사이의 상호 작용, 이용자와 서비스 사이 또는 이용자와 회사의 웹사이트 사이의 각 상호 작용에 대한 정보, 단말 타임존, 이용자가 서비스 접속 시 사용하는 프로그램의 버전, 이용자 단말의 로케일 국가 및 언어, 이용자의 로그인 시점 위치(국가, 도시), 이용자의 사용 가능한 언어</div>
            <h4>3) 회사가 이용자로부터 위탁받는 내담자의 개인정보 항목 </h4>
            <div>본 서비스 이용을 위하여 필수적으로 필요한 내담자 정보는 이메일 주소이며, 그 외의 개인정보 및 민감정보는 선택사항입니다. </div>
            <div>회사는 원칙적으로 서비스의 기능 제공, 운영, 품질 향상 및 개선을 위해 서비스 이용 상에서 상담사로부터 위탁 받은 내담자의 개인정보를 개인정보법 제26조 제5항에 따라 위탁받은 해당 업무 범위 내에서만 사용하고, 별도의 목적으로 수집·이용하거나 제3자에게 제공하지 않습니다. 단, 개인정보법 제23조 제1항 제2호의 ‘법령에서 민감정보의 처리를 허용하는 경우’가 발생할 경우 이에 한하여 수사 기관의 요청에 따라, 회사가 상담사로부터 제공받은 내담자의 개인정보를 제공할 수 있습니다.</div>
            <div>필수 수집정보: 이메일 주소</div>
            <div>선택 수집정보: </div>
            <ul className='ul_dash'>
                <li>개인정보: 이름, 연락처, 생년월일, 성별, 검사결과</li>
                <li>민감정보: 결혼유무, 병역, 최종학력, 직업, 주거형태, 국적, 종교, 가족사항(가족관계, 연령, 이름, 연락처), 복용약물, 상담사가 내담자에 대하여 작성한 글 및 상담 기록</li>
            </ul>
            <h4>&lt;정보주체의 동의를 받아 처리하는개인정보 항목&gt;</h4>
            <div>회사는 다음의 개인정보 항목을 개인정보 보호법 제15조 제1항 제1호 및 제22조 제1항 제7호에 따라  정보주체의 동의를 받아 처리하고 있습니다.</div>
            <table className='table_text_fixed'>
                <tr>
                    <td>수집목적</td>
                    <td>수집항목</td>
                    <td>보유·이용기간</td>
                </tr>
                <tr>
                    <td>이벤트 참여, 서비스 홍보 및 판매 권유를 위한 개인정보 처리</td>
                    <td>이름, 이메일주소, 연락처</td>
                    <td>회원 탈퇴 또는 동의 철회시까지</td>
                </tr>
            </table>
            <h4>나. 개인정보 수집 방법</h4>
            <h4>회원 가입 및 서비스 이용과정에서 이용자의 직접 입력을 통해 개인정보를 수집합니다.</h4>
            <div>이용자의 개인정보는 웹페이지 및 모바일 어플리케이션을 통한 회원 가입 및 서비스 이용과정에서 이용자의 직접 입력을 통해 수집합니다.</div>
            <h4>서비스 이용과정에서 이용자의 정보를 자동으로 수집할 수 있습니다.</h4>
            <div>회사는 이용자의 서비스 이용과정에서 이용자에 관한 정보를 자동화된 방법으로 생성하여 이를 수집할 수 있습니다. 회사는 이용자의 서비스 이용과정에서 이용자의 동의 하에 서비스 이용, 개선을 목적으로 자동으로 수집된 이용자의 개인정보를 수집합니다. 이와 같이 수집된 정보는 개인정보와의 연계 여부 등에 따라 개인정보에 해당할 수 있고, 개인정보에 해당하지 않을 수도 있습니다.</div>
            <h4>3. 개인정보의 처리 및 보유 기간</h4>
            <div>회사는 이용자로부터 개인정보를 수집하는 경우 원칙적으로 이용자로부터 동의를 받은 기간의 범위에서 개인정보를 이용·보관하고, 이용목적이 달성된 경우 이용자의 개인정보를 파기합니다. 다만, 관계법령의 규정에 따라 개인정보를 보존할 필요가 있는 경우 아래와 같이 법령에서 정한 일정 기간 동안 해당 개인정보를 보관합니다.</div>
            <table className='table_text'>
                <tr>
                    <td>구분</td>
                    <td>처리 목적</td>
                    <td>처리 항목</td>
                    <td>보유기간</td>
                </tr>
                <tr>
                    <td>회원가입</td>
                    <td>본인 확인, 이용자 식별, 가입의사 확인</td>
                    <td>이름, 이메일주소, 연락처</td>
                    <td>회원 탈퇴시까지</td>
                </tr>
                <tr>
                    <td>회원관리</td>
                    <td>부정이용 및 비인가 사용 방지, 사기 또는 법령 및 이용약관을 위반하는 이용자에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 기타 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재</td>
                    <td>이름, 이메일주소, 연락처</td>
                    <td>회원 탈퇴시까지</td>
                </tr>
                <tr>
                    <td rowSpan={3}>서비스제공</td>
                    <td>이용자의 상담기록의 저장 및 저장된 상담정보의 검색, 이용자의 효과적인 상담 진행 및 상담 관리, 서비스 이용에 대한 통계 및 이를 활용한 서비스 환경 구축 및 서비스 개선, 증거기반치료와 관련한 과학적 연구를 통해 서비스 개선 및 개발</td>
                    <td>이용자가 작성한 단문 메시지, 이용자가 작성한 글 및 상담 기록, 로그데이터, 쿠키, 접속 정보, IP 주소, 단말 성능, 대역폭, 서비스 이용 통계, 네트워크 유형, 통신사 정보, 단말기 정보(단말기 유형, 모델명, 단말기 아이디, OS) 오류 발생시 오류 발생 일시 및 오류 발생 당시 사용 중인 기능 및 브라우저의 상태, 광고 식별자, 이용자와 다른 이용자 사이의 상호 작용, 이용자와 서비스 사이 또는 이용자와 회사의 웹사이트 사이의 각 상호 작용에 대한 정보, 단말 타임존, 이용자가 서비스 접속 시 사용하는 프로그램의 버전, 이용자 단말의 로케일 국가 및 언어, 이용자의 로그인 시점 위치(국가, 도시), 이용자의 사용 가능한 언어</td>
                    <td>회원 탈퇴시까지</td>
                </tr>
                <tr>
                    <td>서비스 사용에 관련된 결제 처리</td>
                    <td>이름, 연락처, 이메일주소, 결제정보<br/><br/>(신용카드 결제) 카드사명, 카드번호<br/>(환불) 은행명, 계좌번호, 이름<br/>(증빙서류 발급 요청) 성명, 휴대폰번호, 계산서 발급 담당자 정보(이름, 이메일, 연락처)</td>
                    <td>회원 탈퇴 시까지 또는 관련법령에 따른 기간까지</td>
                </tr>
                <tr>
                    <td>서비스 사용과 관련된 모든 문의사항, 불만처리, 공지사항 전달</td>
                    <td>이름, 이메일주소, 연락처, 문의내역</td>
                    <td>회원 탈퇴 시까지 또는 관련법령에 따른 기간까지</td>
                </tr>
                <tr>
                    <td>마케팅 및 광고</td>
                    <td>이벤트 참여, 서비스 홍보 및 판매 권유</td>
                    <td>이름, 이메일주소, 연락처</td>
                    <td>회원 탈퇴 또는 동의 철회시까지</td>
                </tr>
            </table>
            <table className='table_text'>
                <tr>
                    <td>전자상거래 등에서의 소비자 보호에 관한 법률 제6조, 동법 시행령 제6조 제1항</td>
                    <td>• 계약 또는 청약철회 등에 관한 기록 5년<br/>• 대금결제 및 재화 등의 공급에 관한 기록 5년<br/>• 소비자의 불만 또는 분쟁처리에 관한 기록 3년<br/>• 표시광고에 관한 기록 6개월</td>
                </tr>
                <tr>
                    <td>상법 제33조</td>
                    <td>• 상업장부와 영업에 관한 중요서류에 포함된 정보 10년전표 또는 이와 유사한 서류에 포함된 정보 5년</td>
                </tr>
                <tr>
                    <td>통신비밀보호법 제15조의2, 동법 시행령 제41조 제2항</td>
                    <td>• 이용자의 인터넷 등 로그기록, 접속지 추적자료 3개월<br/>• 그 외의 통신사실확인자료 12개월</td>
                </tr>
            </table>
            <h4>4. 개인정보의 파기 절차 및 방법에 관한 사항</h4>
            <div>이용자의 개인정보 수집 및 이용 목적이 달성될 경우 회사는 개인정보 보관기간에 별도의 동의를 얻은 경우나 법령에 따라 일정기간 보관하여야 하는 경우를 제외하고는 귀하의 개인정보를 지체없이 파기합니다. 다른 법령에 따라 보존하는 개인정보의 항목과 보존 근거는 본 방침침의 제4조의 “가.  개인정보의 보유 및 이용기간” 항목에서 확인 가능합니다.</div>
            <div>개인정보처리자는 파기 사유가 발생한 개인정보를 선정하고, 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. 전자적 파일 형태로 저장된 개인정보는 기록을 복구하거나 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</div>
            <h4>5. 개인정보의 안전성 확보 조치에 관한 사항</h4>
            <div>회사는 이용자들의 개인정보를 처리함에 있어, 외부로부터의 무단 접근을 통제하고, 개인정보처리시스템에 대한 접속기록을 보관하며, 개인정보 처리 직원을 대상으로 정기적인 사내 교육을 실시하고, 사내에 “개인정보 보호지침”을 제정하여 준수하는 등 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 개인정보법에서 정한 안전성 확보 조치를 취하고 있습니다.</div>
            <ol>
                <li>관리적 조치 : 내부관리계획 수립 및 시행, 전담조직 운영, 정기적 직원 교육</li>
                <li>기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 개인정보의 암호화, 보안프로그램 설치 및 갱신</li>
                <li>물리적 조치 : 직원 외 기기, 자료보관실 접근 통제</li>
            </ol>
            <h4>6. 가명정보 처리에 관한 사항</h4>
            <div>회사는 수집한 내담자의 정보를 개인정보 보호법 제28조의 2에 따라 통계작성, 과학적 연구, 공익적 기록보존 등을 위하여 특정 개인을 알아볼 수 없도록 가명처리하여 다음과 같이 활용할 수 있습니다. 가명정보는 재식별되지 않도록 추가정보와 분리하여 별도 저장, 관리하고 필요한 기술적, 관리적 보호조치를 취합니다.</div>
            <table className='table_text_fixed'>
                <tr>
                    <td>구분</td>
                    <td>처리 목적</td>
                    <td>처리항목</td>
                    <td>처리 기간</td>
                </tr>
                <tr>
                    <td>과학적 연구</td>
                    <td>서비스 개선을 위한 증거기반치료 관련 연구</td>
                    <td>생년, 성별, 검사결과, 최종학력, 국적, 복용약물</td>
                    <td>연구목적 달성시까지지</td>
                </tr>
            </table>
            <div>개인정보 보호법 제28조의 4(가명정보에 대한 안전조치 의무 등)에 따른 가명정보의 안정성 확보조치에 관한 사항은 본 방침의 제9조 “개인정보의 안정성 확보 조치”를 따릅니다.</div>
            <h4>7. 개인정보 자동 수집 장치의 설치ᐧ운영 및 거부에 관한 사항</h4>
            <div>회사는 서비스 제공에 필요한 접속로그, 기기정보 등을 자동으로 수집하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 서비스를 운영하는데 이용되는 서버(HTTP)가 이용자의 웹/모바일기기 브라우저에게 보내는 소량의 정보이며 이용자들의 컴퓨터/모바일기기 내의 하드디스크에 저장되기도 합니다.</div>
            <h4>가. 쿠키의 사용 목적</h4>
            <div>이용자의 로그인 및 최근 접속기록을 토대로 이용자 상호간 커뮤니케이션의 편리한 기능, 이용자 행태 분석을 통한 맞춤형 서비스 등을 제공하고 서비스를 개선하기 위하여 활용됩니다.</div>
            <h4>나. 쿠키의 설치/운영 및 거부</h4>
            <div>이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹/모바일기기 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 이용에 어려움이 있을 수 있습니다.</div>
            <h4>8. 정보 주체의 권리와 그 행사방법</h4>
            <div>이용자는 언제든지 직접 또는 회사의 개인정보 보호책임자를 통하여 자신의 개인정보(단, 이메일 주소를 ID로 하여 가입한 경우 이메일 주소는 제외)를 서비스 내 “개인정보 수정”에서 조회하거나 수정 및 삭제 또는 처리정지(회원탈퇴)를 요구할 수 있습니다. 개인정보 보호책임자를 통하여 이용자의 권리를 행사하고자 할 경우, 본 개인정보 처리방침 제9조에서 정한 연락처 또는 전자우편으로 연락하여 주시기 바랍니다. 다만, 이러한 이용자의 권리는 회사의 관리 및 통제 범위가 미치는 개인정보에 한하여 인정될 수 있습니다. 이용자는 언제든지 서비스 내 “개인정보 수정”에서 “회원탈퇴” 신청 등을 통해 개인정보의 수집∙이용에 대한 동의 철회 또는 가입 해지를 할 수 있으며, 이러한 신청은 즉시 처리됩니다. 만일 서비스 내에서 위와 같은 조치가 원활하지 않을 경우에는 회사에 요청하시면 신속히 처리하겠습니다.</div>
            <h4>9. 개인정보 보호책임자</h4>
            <div>회사는 이용자의 개인정보 보호 업무 및 관련 고충사항 처리를 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 귀하가 서비스를 이용하면서 발생하는 모든 개인정보보호 관련 문의, 불만, 의견이나 기타 사항은 개인정보보호 책임자에게 연락해 주시기 바랍니다. 회사는 이용자 여러분의 목소리에 귀 기울이고 신속하고 충분한 답변을 드릴 수 있도록 최선을 다하겠습니다.</div>
            <div>[개인정보보호책임자]</div>
            <div>이름: 연규진</div>
            <div>직책: CEO</div>
            <div>또는 maumsensor@gmail.com로 문의하실 수 있습니다.</div>
            <h4>10. 정보 주체의 권익침해에 대한 구제방법</h4>
            <div>정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.</div>
            <ol>
                <li>개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</li>
                <li>개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.go.kr)</li>
                <li>대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
                <li>경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
            </ol>
            <div>개인정보법 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.</div>
            <div>중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)</div>
            <h4>11. 기타</h4>
            <h4>회사의 책임 범위</h4>
            <div>회사는 회사의 과실 없이 이용자 또는 제3자의 부주의로 인하여 이용자의 개인정보가 분실·도난·유출·위조·변조 또는 훼손되어 발생한 손해에 대하여 책임을 부담하지 않습니다.</div>
            <h4>고지</h4>
            <div>회사는 관련 법령, 내부정책 변경 등에 따라 본 개인정보 처리방침을 수정할 수 있고, 이러한 경우 인터넷 홈페이지 또는 별도의 창을 통하거나 이메일, 웹브라우저 Push 메시지 및 카카오톡 메시지와 문자메시지 (SMS)를 통하여 수정내용을 공지하도록 하겠습니다.</div>
            <h4>아웃 바운드 링크</h4>
            <div>귀하가, 서비스 또는 회사의 웹사이트를 경유하는 등의 방법으로 제3자가 제공하는 웹사이트, 제품 또는 서비스에 접근하는 경우, 해당 제3자가 귀하의 개인정보를 수집할 수 있습니다. 이러한 형태의 개인정보 처리에 대해서는 해당 제3자의 개인정보 처리방침을 확인하시기 바랍니다. 귀하와 제3자 사이에 귀하의 개인정보에 관한 처리에는 본 정책이 적용되지 않습니다.</div>
            <h4>본 개인정보 처리방침은 2024. 11. 13 부터 시행됩니다.</h4>
        </>
    );
};

export default Privacy;