import React from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { ItemMultipleChoice, ItemShortForm, ItemLikert } from './Items/View';
import { YyyyMmDd } from './Function/ChangeText';
import { useParams } from 'react-router-dom';

// Create Document Component
const TablePDFView = (props) => {
  return (
    <>
    {props.loading ? 
    <Grid style={{ padding: '60px'}}>
      <CircularProgress />
    </Grid>
    :
    <Grid >
        <h1 style={{ textAlign: 'center' }}>{props.title}</h1>
        <p style={{textAlign: 'right', paddingRight: '16px' }}>
            <b>{props.client.Name_decrypt ? `${props.client.Name_decrypt} (${props.client.NickName})` : props.client.NickName}</b>
        </p>
        {/* <p>{props.scale.Scale_id.Detail}</p> */}
        <Grid>
        <div className='table_container full_width' id='table_top'>
        {props.datas.map((data, i) => (
        <Grid container style={{ marginTop: '8px' }} spacing={1} key={i}>
            {i !== 0 ? <div style={{ borderTop: '1px solid #C0C0C0', margin: '5px', width: '100%' }}/> : null}
            {data.map((item, j) => (
            <Grid item xs={12} key={j}>
                <Grid
                    container
                    style={{
                        borderRadius: '5px',
                        border: '1px solid #C0C0C0'
                    }}
                    alignItems={item.label === '내용' ? 'stretch' : 'center'}
                >
                    <Grid
                        item 
                        xs={4} sm={3} md={1.5} 
                        style={{ 
                            backgroundColor: '#D9D9D9', 
                            textAlign: 'center', 
                            fontWeight: 'bold', 
                            borderRadius: '5px 0px 0px 5px', 
                            padding: '4px 16px', 
                            overflow: 'hidden', 
                            whiteSpace: 'nowrap', 
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {item.label}
                    </Grid>
                    <Grid 
                        item
                        xs={8} sm={9} md={10.5}
                        style={{
                            paddingLeft: '16px',
                            paddingTop: item.label === '내용' ? '4px' : '0px',
                            paddingBottom: item.label === '내용' ? '4px' : '0px'
                        }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: item.value ? item.value.replaceAll('\n', '<br/>') : 'ㅤ'}}/>
                    </Grid>
                </Grid>
            </Grid>
            ))}
        </Grid>
        ))}
        </div>
        </Grid>
      </Grid>}
      </>
)};

export default TablePDFView;