import React, { useEffect, useState } from 'react';
import { Grid, Link, Checkbox } from '@mui/material';
import { useSnackbar } from 'notistack';

import { LoginLayout } from 'components/Layout';
import { TextForm, EmailForm, PasswordForm } from 'components/Items/Form';
import { LargeCancelButton, LargeSubmitButton } from 'components/Items/Button';
import { FullScreenModal } from 'components/Items/Modal';
import Privacy from 'components/Policy/Privacy/20241113';
import Service from 'components/Policy/Service/20241113';

const RegisterView = (props) => {
    const manager = props.manager;
    const setManager = props.setManager;
    const { enqueueSnackbar } = useSnackbar();
    const regEmail = new RegExp('[a-z0-9]+@[a-z]+?\.[a-zA-Z]{2,3}'); // eslint-disable-line
    const regPw = new RegExp('(?=.*\d)(?=.*[a-z]){8,15}'); // eslint-disable-line
    const regPhone = new RegExp('[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}'); // eslint-disable-line
    const regKo = new RegExp(/^[ㄱ-ㅎㅏ-ㅣ가-힣]+$/); // eslint-disable-line
    const regNum = new RegExp(/^[0-9]+$/); // eslint-disable-line

    const [page, setPage] = useState(0);
    const [openPrivacyModal, setOpenPrivacyModal] = useState(false);
    const [openTermModal, setOpenTermModal] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [certNumberError, setCertNumberError] = useState(false);
    const [showPassword, setShowPassowrd] = useState(false);
    const [disabledCreateBtn, setDisabledCreateBtn] = useState(true);
    
    const handleChangePrivacyAgree = () => {
        setManager({ ...manager, PrivacyAgree: !manager.PrivacyAgree });
    };
    const handleChangeTermAgree = () => {
        setManager({ ...manager, TermAgree: !manager.TermAgree });
    };
    const handleChangeName = (e) => {
        if(regKo.test(e.target.value) || e.target.value === ''){
            setNameError(false);
            setManager({ ...manager, Name: e.target.value });
        }else{
            setNameError(true);
        }
    };
    const handleChangeEmail = (e) => {
        setManager({ ...manager, Email: e.target.value });
    };
    const handleChangeCertNumber = (e) => {
        if(regNum.test(e.target.value) || e.target.value === ''){
            setCertNumberError(false);
            setManager({ ...manager, CertNumber: e.target.value });
        }else{
            setCertNumberError(true);
        }
    };
    const handleChangePassword = (e) => {
        setManager({ ...manager, Password: e.target.value });
    };
    const handleChangePasswordCheck = (e) => {
        setManager({ ...manager, PasswordCheck: e.target.value });
    };
    const handleChangePhone = (e) => {
        let tump = e.target.value.replaceAll('-', '');
        tump = tump.replace(/[\a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]/g, ''); // eslint-disable-line
        tump = tump.replace(/[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi, ''); // eslint-disable-line
        if(tump.length < 12){
            if(tump.length === 9){
                tump = tump.replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }else if(tump.length === 10){
                tump = tump.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }else if(tump.length === 11){
                tump = tump.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }
            setManager({ ...manager, Phone: tump });
        }else{
            return false;
        }
    };
    const handleClickEmailChange = () => {
        setManager({ ...manager, EmailCheck: false, Email: '', CertNumber: '' });
        props.setCode('');
    };
    const handleClickEmailCheck = () => {
        if(props.code === manager.CertNumber){
            enqueueSnackbar('이메일 인증이 완료되었습니다.', { variant: 'success' });
            setManager({ ...manager, EmailCheck: true });
        }else{
            enqueueSnackbar('인증번호가 일치하지 않습니다.', { variant: 'error' });
        }
    };

    useEffect(() => {
        let tump = false;
        if(!manager.Name){
            tump = true;
        }
        if(!manager.EmailCheck){
            tump = true;
        }
        if(!manager.Password){
            tump = true;
        }
        if(manager.Password !== manager.PasswordCheck){
            tump = true;
        }
        if(!regPhone.test(manager.Phone) || manager.Phone === ''){
            tump = true;
        }
        setDisabledCreateBtn(tump);
    }, [manager, setDisabledCreateBtn, regPhone]);

    return (
        <>
        <LoginLayout>
            <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                style={{
                    minHeight: '100vh',
                    padding: '5em 1em',
                    position: 'relative',
                    zIndex: '2'
                }}
            >   
                {page === 0 ? 
                <Grid
                    xs={12} sm={10} md={8} lg={6} xl={4}
                    item
                    style={{
                        backgroundColor: '#FFF',
                        borderRadius: '30px',
                        padding: '60px 4%'
                    }}
                >
                    <h1 style={{ textAlign: 'center' }}>회원가입</h1>
                    <Grid container alignItems={'center'}>
                        <Grid item>
                            <Checkbox checked={manager.PrivacyAgree} onChange={handleChangePrivacyAgree}/>
                        </Grid>
                        <Grid item>
                            <Link className='link_underline' onClick={() => setOpenPrivacyModal(true)}>개인정보처리방침</Link>에 동의합니다.
                        </Grid>
                    </Grid>
                    <Grid container alignItems={'center'} style={{ marginBottom: '16px' }}>
                        <Grid item>
                            <Checkbox checked={manager.TermAgree} onChange={handleChangeTermAgree}/>
                        </Grid>
                        <Grid item>
                            <Link className='link_underline' onClick={() => setOpenTermModal(true)}>이용약관</Link>에 동의합니다.
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item xs={6} md={4} lg={3}>
                            <LargeSubmitButton
                                label='다음'
                                loading={false}
                                handleClick={() => setPage(page+1)}
                            />
                        </Grid>
                    </Grid>
                </Grid>:
                <Grid
                    xs={12} sm={10} md={8} lg={6} xl={4}
                    item
                    style={{
                        backgroundColor: '#FFF',
                        borderRadius: '30px',
                        padding: '60px 4%'
                    }}
                >
                    <h1 style={{ textAlign: 'center' }}>회원가입</h1>
                    <TextForm
                        label='이름'
                        required={true}
                        error={nameError}
                        errorMessage='한글만 입력이 가능합니다.'
                        value={manager.Name}
                        placeholder='이름을 입력해주세요.'
                        handleChange={handleChangeName}
                    />
                    <Grid container spacing={1} alignItems={'end'}>
                        <Grid item xs={9}>
                            <EmailForm
                                label='이메일'
                                required={true}
                                disabled={props.code}
                                error={!regEmail.test(manager.Email) && manager.Email !== ''}
                                errorMessage='이메일 형식이 일치하지 않습니다.'
                                value={manager.Email}
                                handleChange={handleChangeEmail}
                            />
                        </Grid>
                        {!props.code ?
                        <Grid item xs={3}>
                            <LargeSubmitButton
                                label='인증하기'
                                loading={props.emailLoading}
                                disabled={!regEmail.test(manager.Email)}
                                handleClick={props.handleClickEmailCheck}
                            />
                        </Grid>:
                        <Grid item xs={3}>
                            <LargeSubmitButton
                                label='변경하기'
                                loading={false}
                                disabled={false}
                                handleClick={handleClickEmailChange}
                            />
                        </Grid>}
                    </Grid>
                    <Grid container spacing={1} alignItems={'end'}>
                        <Grid item xs={9}>
                            <TextForm
                                label='인증번호'
                                placeholder='인증번호를 입력해주세요.'
                                required={true}
                                disabled={manager.EmailCheck}
                                error={certNumberError}
                                errorMessage='숫자만 입력이 가능합니다.'
                                value={manager.CertNumber}
                                handleChange={handleChangeCertNumber}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <LargeSubmitButton
                                label='확인'
                                loading={false}
                                disabled={!manager.CertNumber || manager.EmailCheck}
                                handleClick={handleClickEmailCheck}
                            />
                        </Grid>
                    </Grid>
                    <PasswordForm
                        label='비밀번호'
                        required={true}
                        // error={!regPw.test(manager.Password) && manager.Password !== ''}
                        // errorMessage='특수문자를 포함하여 8~15자로 입력해 주세요.'
                        placeholder={'특수문자를 포함하여 8~15자로 입력해 주세요.'}
                        showPassword={showPassword}
                        handleClick={() => setShowPassowrd(!showPassword)}
                        value={manager.Password}
                        handleChange={handleChangePassword}
                    />
                    <PasswordForm
                        label='비밀번호 확인'
                        required={true}
                        error={manager.PasswordCheck !== manager.Password && manager.PasswordCheck !== ''}
                        errorMessage={'비밀번호가 일치하지 않습니다.'}
                        placeholder={'특수문자를 포함하여 8~15자로 입력해 주세요.'}
                        showPassword={showPassword}
                        handleClick={() => setShowPassowrd(!showPassword)}
                        value={manager.PasswordCheck}
                        handleChange={handleChangePasswordCheck}
                    />
                    <TextForm
                        label='연락처'
                        placeholder='연락처를 입력해주세요.'
                        required={true}
                        error={!regPhone.test(manager.Phone) && manager.Phone !== ''}
                        errorMessage='연락처 형식이 올바르지 않습니다.'
                        value={manager.Phone}
                        handleChange={handleChangePhone}
                    />
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item xs={6} md={4} lg={3}>
                            <LargeCancelButton
                                label='이전'
                                loading={false}
                                handleClick={() => setPage(page-1)}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} lg={3}>
                            <LargeSubmitButton
                                label='완료'
                                loading={props.creating}
                                disabled={disabledCreateBtn}
                                handleClick={props.handleClickRegister}
                            />
                        </Grid>
                    </Grid>
                </Grid>}
            </Grid>
            <FullScreenModal
                open={openPrivacyModal}
                handleClose={() => setOpenPrivacyModal(false)}
                title='개인정보처리방침'
            >
                <div className='notice'>
                    <Privacy />
                </div>
            </FullScreenModal>
            <FullScreenModal
                open={openTermModal}
                handleClose={() => setOpenTermModal(false)}
                title='이용약관'
            >
                <div className='notice'>
                    <Service />
                </div>
            </FullScreenModal>
        </LoginLayout>
        </>
    );
};

export default RegisterView;