import React from 'react';
import { Grid } from '@mui/material';
import { SmallSubmitButton, TooltipButton } from 'components/Items/Button';
const ListContainer = (props) => {
    return (
        
        <Grid item xs={12} style={{ backgroundColor: '#FFF', borderRadius: '20px', marginBottom: '16px', overflow: 'hidden' }} sx={{ boxShadow: 1 }}>
            <h2 style={{ margin: '0', borderRadius: '20px 20px 0 0', padding: '16px 24px' }}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        {props.title}
                        {props.tooltip ?
                        <TooltipButton tooltip={props.tooltip} size={'normal'}/>
                        :null}
                    </Grid>
                    <Grid item>
                        {props.button ?
                        <SmallSubmitButton
                            label={props.button.label}
                            handleClick={props.button.handleClick}
                        />
                        :null}
                    </Grid>
                </Grid>
            </h2>
            <Grid style={{ padding: '0px 24px 16px 24px' }}>
                {props.children}
            </Grid>
        </Grid>
    );
};

export default ListContainer;