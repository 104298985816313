import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PDFView from 'components/BasicPDFView';
import { ScheduleKindKo, ServiceKo, SessionStateKo, YyyyMmDd, YyyyMmDdHhMmSs } from 'components/Function/ChangeText';

const BasicPDFContainer = () => {
    const { clientNo, kind } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('');
    const [datas, setDatas] = useState([]);
    // window.onafterprint = function(){
    //     window.close();
    // };
    const [client, setClient] = useState({

    });

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/client/${clientNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                console.log(res.data)
                if(kind === 'receipt'){ 
                    setTitle('접수 면접 기록');
                    setDatas([[
                        { label: '상담자', value: res.data.ClientData.Receipt?.Name },
                        { label: '접수면접 날짜 및 시간', value: `${res.data.ClientData.Receipt?.Day} ${res.data.ClientData.Receipt?.Time}` },
                        { label: '내용', value: res.data.ClientData.Receipt?.Note },
                        { label: '작성 날짜', value: YyyyMmDdHhMmSs(res.data.ClientData.Receipt?.createdAt) }
                    ]]);
                }else if(kind === 'reference'){
                    setTitle('검사 관련 노트');
                    let tumpData = [];
                    for(let i = 0; i < res.data.ClientData.Reference.length; i++){
                        tumpData.push([
                            { label: '검사 제목', value: res.data.ClientData.Reference[i].Title },
                            { label: '상담자', value: res.data.ClientData.Reference[i].Name },
                            { label: '날짜', value: res.data.ClientData.Reference[i].day },
                            { label: '내용', value: res.data.ClientData.Reference[i].Note },
                            { label: '작성 날짜', value: YyyyMmDdHhMmSs(res.data.ClientData.Reference[i].createdAt) }
                        ]);
                    }
                    if(tumpData.length === 0){
                        tumpData.push([
                            { label: '검사 제목', value: '' },
                            { label: '상담자', value: '' },
                            { label: '날짜', value: '' },
                            { label: '내용', value: '' },
                            { label: '작성 날짜', value: '' },
                        ]);
                    }
                    setDatas(tumpData);
                }else if(kind === 'report'){
                    setTitle('종결 보고서');
                    setDatas([[
                        { label: '상담자', value: res.data.ClientData.Report?.Name },
                        { label: '상담 시작 날짜', value: res.data.ClientData.Report?.StartDay },
                        { label: '상담 종료 날짜', value: res.data.ClientData.Report?.EndDay },
                        { label: '상담 횟수', value: res.data.ClientData.Report?.Count.toString() },
                        { label: '내용', value: res.data.ClientData.Report?.Note },
                        { label: '작성 날짜', value: YyyyMmDdHhMmSs(res.data.ClientData.Report?.createdAt) }
                    ]]);
                }else if(kind === 'request'){
                    setTitle('의뢰서');
                    setDatas([[
                        { label: '상담자', value: res.data.ClientData.request?.Name },
                        { label: '내용', value: res.data.ClientData.request?.Note },
                        { label: '작성 날짜', value: YyyyMmDdHhMmSs(res.data.ClientData.request?.createdAt) }
                    ]]);
                }else if(kind === 'etc'){
                    setTitle('기타');
                    setDatas([[
                        { label: '제목', value: res.data.ClientData.ETC?.Title },
                        { label: '상담자', value: res.data.ClientData.ETC?.Name },
                        { label: '내용', value: res.data.ClientData.ETC?.Note },
                        { label: '작성 날짜', value: YyyyMmDdHhMmSs(res.data.ClientData.ETC?.createdAt) }
                    ]]);
                }
                setClient(res.data.ClientData);
                setLoading(false)
                // setTimeout(() => {
                //     setLoading(false);
                //     setTimeout(() => {
                //         window.print();
                //     }, [200]);
                // }, [1800]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [setClient, setDatas, enqueueSnackbar, cookies.LoginKey]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return(
        <PDFView 
            loading={loading}
            client={client}
            title={title}
            datas={datas}
        />
    );
};

export default BasicPDFContainer;